import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Modal, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Chip, CardContent, Card, CardHeader, CircularProgress, Collapse, IconButton, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { resetInstanceId, resetProgress, validateAdminToken, getAdminPortalData, getContainerData, startContainerGroup, stopContainerGroup, restartContainerGroup, getContainerLogs } from '../services/api';

const AdminPortal = () => {
    const validateTokenPageRefresh = useRef(true);
    const pageRefresh = useRef(true);
    const [users, setUsers] = useState([]);
    const [continuationToken, setContinuationToken] = useState(null);
    const [containerGroups, setContainerGroups] = useState([]);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [loadingGroup, setLoadingGroup] = useState(null); // Tracks which group is being acted upon
    const [lastUpdated, setLastUpdated] = useState(null); // Tracks when data was last updated
    const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0); // Tracks seconds since last update
    const intervalRef = useRef(null); // Reference to the interval timer
    const refreshingData = useRef(true);

    //log visualization
    const [logModalOpen, setLogModalOpen] = useState(false); // State to manage modal visibility
    const [currentLogs, setCurrentLogs] = useState(''); // State to store current logs
    const [loadingLogs, setLoadingLogs] = useState(false); // State to manage log loading

    const handleViewLogs = async (resourceGroup, groupName, containerName) => {
        setLoadingLogs(true);
        try {
            const userToken = sessionStorage.getItem('token');
            const logs = await getContainerLogs(userToken, resourceGroup, groupName, containerName);
            setCurrentLogs(logs.content); // Set the logs to state
            setLogModalOpen(true); // Open the modal
        } catch (error) {
            console.error('Error retrieving logs:', error);
            setCurrentLogs('Failed to retrieve logs.');
            setLogModalOpen(true);
        } finally {
            setLoadingLogs(false);
        }
    };

    const handleCloseModal = () => {
        setLogModalOpen(false);
        setCurrentLogs('');
    };

    const [validatingAdminAccess, setValidatingAdminAccess] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            const token = sessionStorage.getItem('token');
            
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await validateAdminToken(token);
                if(response.message === 'User is an admin') {
                    setValidatingAdminAccess(false);
                } else {
                    toast.error('User is not an admin');
                    navigate('/login');
                }
            } catch (err) {
                toast.error(err.message || 'Failed to login with Pedegree Credentials');
                // setError('Invalid token or insufficient permissions');
                navigate('/login'); // Redirect to login if the token is invalid or insufficient
            }
        };
        if(validateTokenPageRefresh.current) {
            validateToken();
            validateTokenPageRefresh.current = false;
        }
    }, [validateTokenPageRefresh, navigate]);

    const fetchContainerData = useCallback(async () => {

        refreshingData.current = false;
        try{
            const userToken = sessionStorage.getItem('token');
            const data = await getContainerData(userToken);
            setContainerGroups(data);
            const now = new Date();
            setLastUpdated(now);
            setTimeSinceLastUpdate(0); // Reset time since last update
        }
        catch(error){
            console.error('Error fetching container data:', error);
            toast.error('Failed to fetch container data');
        }finally{
            refreshingData.current = true;
        }
    }, []);

    useEffect(() => {
        fetchContainerData();
        // Set up the interval for updating the last updated label
        intervalRef.current = setInterval(() => {
            setTimeSinceLastUpdate(prevTime => prevTime + 1);
        }, 1000); // Start with 1 second interval

        return () => clearInterval(intervalRef.current); // Clean up interval on component unmount
    }, [fetchContainerData]);

    useEffect(() => {
        // Adjust the interval based on the time since the last update
        clearInterval(intervalRef.current);
        let interval;

        if (timeSinceLastUpdate < 30) {
            interval = 1000; // Update every second
        } else if (timeSinceLastUpdate < 120) {
            interval = 10000; // Update every 10 seconds
        } else if (timeSinceLastUpdate < 600) {
            interval = 60000; // Update every minute
        } else {
            interval = 600000; // Update every 10 minutes
        }

        intervalRef.current = setInterval(() => {
            setTimeSinceLastUpdate(prevTime => prevTime + interval/1000);
        }, interval);

        return () => clearInterval(intervalRef.current); // Clean up interval on changes
    }, [timeSinceLastUpdate]);

   

    const handleExpandClick = (groupName) => {
        setExpandedGroup(expandedGroup === groupName ? null : groupName);
    };

    const handleAction = async (action, resourceGroup, groupName) => {
        const userToken = sessionStorage.getItem('token');
        setLoadingGroup(groupName); // Set the loading state
        try {
            if (action === 'start') {
                await startContainerGroup(userToken, resourceGroup, groupName);
            } else if (action === 'stop') {
                await stopContainerGroup(userToken, resourceGroup, groupName);
            } else if (action === 'restart') {
                await restartContainerGroup(userToken, resourceGroup, groupName);
            }
            await fetchContainerData(); // Refresh data after action
        } finally {
            setLoadingGroup(null); // Clear the loading state
        }
    };

    const handleReload = () => {
        fetchContainerData(); // Manually fetch new data
    };

    // const handleViewLogs = async (resourceGroup, groupName, containerName) => {
    //     const userToken = sessionStorage.getItem('token');
    //     const logs = await getContainerLogs(userToken, resourceGroup, groupName, containerName);
    //     // // Display logs - you can implement a modal or download the logs
    //     alert(`Logs for ${containerName}: ${logs}`);
    // };

    // Style objects for modal and log paper
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const logPaperStyle = {
    marginTop: 2,
    padding: 2,
    maxHeight: '60vh',
    overflow: 'auto',
    backgroundColor: '#1e1e1e',
    color: '#dcdcdc',
    borderRadius: '4px',
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
};

    const getStateColor = (state) => {
        switch (state) {
            case 'Running':
                return 'success'; // Green
            case 'Stopped':
                return 'error'; // Red
            default:
                return 'default'; // Grey
        }
    };

    const isStartDisabled = (state, groupName) => loadingGroup === groupName || state === 'Running' || state !== 'Stopped';
    const isStopOrRestartDisabled = (state, groupName) => loadingGroup === groupName || state === 'Stopped' || state !== 'Running';

    const formatTimeSinceUpdate = () => {
        if (timeSinceLastUpdate < 60) {
            return `${timeSinceLastUpdate} seconds ago`;
        } else if (timeSinceLastUpdate < 3600) {
            const minutes = Math.floor(timeSinceLastUpdate / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            const hours = Math.floor(timeSinceLastUpdate / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        }
    };

    const fetchNextPage = useCallback(async () => {
        const userToken = sessionStorage.getItem('token');
        const { users: userInfo, continuationToken: continuation } = await getAdminPortalData(userToken, 5, continuationToken);

        setUsers(prevUsers => [...prevUsers, ...userInfo]);
        if(continuation) {
            setContinuationToken(continuation);
        }
    }, [continuationToken]);

    useEffect(() => {
        // Fetch the first page of data
        if (pageRefresh.current) {
            fetchNextPage();
            pageRefresh.current = false;
        }
    }, [pageRefresh, fetchNextPage]);


    const [openDialog, setOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);

    // Function to handle dialog open
    const handleOpenDialog = (action, userId) => {
        setCurrentAction(action);
        setCurrentUserId(userId);
        setOpenDialog(true);
    };

    // Function to handle dialog close
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentAction(null);
        setCurrentUserId(null);
    };

    // Handle instance ID reset
    const handleResetInstanceId = async (userId) => {
        handleOpenDialog('resetInstanceId', userId);
    };

    // Handle progress reset
    const handleResetProgress = async (userId) => {
        handleOpenDialog('resetProgress', userId);
    };

  // Confirm action
  const handleConfirmAction = async () => {
    try {
      const token = sessionStorage.getItem('token');
      if (currentAction === 'resetInstanceId') {
        await resetInstanceId(token, currentUserId); // Uncomment when integrating with API
        setUsers(users.map(user => user.id === currentUserId ? { ...user, instanceId: -1 } : user));
      } else if (currentAction === 'resetProgress') {
        await resetProgress(token, currentUserId); // Uncomment when integrating with API
        setUsers(users.map(user => user.id === currentUserId ? { ...user, progress: 0 } : user));
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Failed to perform action:', error);
    }
  };

    // // Handle instance ID reset
    // const handleResetInstanceId = async (userId) => {
    //     try {
    //         // await resetInstanceId(userId);
    //         // Optionally refresh the data after the reset
    //         setUsers(users.map(user => user.id === userId ? { ...user, instanceId: -1 } : user));
    //     } catch (error) {
    //         console.error('Failed to reset instance ID:', error);
    //     }
    // };

    // // Handle progress reset
    // const handleResetProgress = async (userId) => {
    //     try {
    //         // await resetProgress(userId);
    //         // Optionally refresh the data after the reset
    //         setUsers(users.map(user => user.id === userId ? { ...user, progress: 0 } : user));
    //     } catch (error) {
    //         console.error('Failed to reset progress:', error);
    //     }
    // };

      const parseAndFormatData = (dataString) => {
        try {
          const questData = JSON.parse(dataString);
      
          if (Array.isArray(questData)) {
            return questData.map(([name, details], index) => (
              <Box key={index} sx={{ marginBottom: '8px' }}>
                <Typography variant="body2" component="div">
                  <strong>{name}</strong>
                </Typography>
                <Typography variant="body2" component="div">
                  Step: {details.CurrentStep}/{details.MaximumStep}
                </Typography>
              </Box>
            ));
          } else {
            return <Typography variant="body2">No progress</Typography>;
          }
        } catch (error) {
        //   console.error('Error parsing JSON:', error);
          return <Typography variant="body2">No progress</Typography>;
        }
      };
      

    const columns = [
        { field: 'username', headerName: 'Name', flex: .75 },
        { field: 'gamerTag', headerName: 'Gamertag', flex: .75 },
        { field: 'active', headerName: 'Active', flex: .5, renderCell: 
            (params) => (
                <Chip label={params.value ? 'active' : 'inactive'} color={params.value ? 'success' : 'secondary'} />
            )
         },
        {
            field: 'instanceId',
            headerName: 'Instance ID',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.value} 
                    <IconButton
                        onClick={() => handleResetInstanceId(params.row.id)}
                        title="Reset Instance ID"
                        size="small"
                        style={{ marginLeft: 8, alignContent: "flex-end" }} // Add some space between the value and the icon
                    >
                        <RestartAltIcon />
                    </IconButton>
                </>
            ),
        },
        {
            field: 'progress',
            headerName: 'Progress',
            flex: 3,
            renderCell: (params) => (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'top',

                 }}>
                    <IconButton
                        onClick={() => handleResetProgress(params.row.id)}
                        title="Reset Progress"
                        size="small"
                        sx={{ marginRight: 1, color: 'red' }}
                        >
                    <DeleteForeverIcon />
                    </IconButton>
                    <Box sx={{
                        height: '50px', // Set the height for the box
                        width: '100%', // Set the width for the box
                        overflowY: 'auto', // Enable vertical scrolling
                        paddingRight: 1, // Optional: Add some padding to avoid content cutting off
                    }}>
                    {parseAndFormatData(params.value)}
                    </Box>
                </Box>
            ),
        },
    ];

    if(validatingAdminAccess) {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    return (
    
    <Grid container spacing={2} sx={{ justifyContent: 'left' }}>
        <Modal open={logModalOpen} onClose={handleCloseModal} aria-labelledby="log-modal-title">
                <Box sx={modalStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="log-modal-title" variant="h6">
                            Container Logs
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Paper sx={logPaperStyle}>
                        {loadingLogs ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <pre>{currentLogs}</pre>
                        )}
                    </Paper>
                </Box>
            </Modal>
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            {currentAction === 'resetInstanceId' ? 'Are you sure you want to reset the instance ID?' : 'Are you sure you want to reset the progress?'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
        <ToastContainer />
        <Grid item xs={12}>
          <Card sx={{ display: 'flex', flexDirection: 'column', p: 2, height: '100%' }}>
            <CardHeader sx={{ display: 'flex' }} title="Profile"></CardHeader>
            <CardContent>
            <DataGrid
                rows={users}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // getRowId={getRowId}
                // pagination
                // autoHeight
                getRowHeight={(params) => {
                    const content = parseAndFormatData(params.progress);
                    const lineCount = content ? content.length : 1; // Estimate line count based on parsed content
                    return 50 + lineCount * 20; // Calculate height based on content
                }}
            />
            {continuationToken && (
                <Button onClick={fetchNextPage}>Load More</Button>
            )}
            </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                    <CardHeader
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        title="Container Instances"
                        action={
                            <>
                                <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                                    Last updated: {lastUpdated ? formatTimeSinceUpdate() : 'Never'}
                                </Typography>
                                <IconButton onClick={handleReload} title="Reload Data">
                                    <RefreshIcon />
                                </IconButton>
                            </>
                        }
                    />
                    <CardContent>
                        {!refreshingData.current ? <CircularProgress /> : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {containerGroups.map((group) => (
                                    <React.Fragment key={group.name}>
                                        <TableRow>
                                            <TableCell>
                                                <IconButton onClick={() => handleExpandClick(group.name)} disabled={loadingGroup === group.name}>
                                                    {expandedGroup === group.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                                {group.name}
                                            </TableCell>
                                            <TableCell>
                                                <Chip label={group.state} color={getStateColor(group.state)} />
                                            </TableCell>
                                            <TableCell>
                                                {loadingGroup === group.name ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            onClick={() => handleAction('start', group.resourceGroup, group.name)}
                                                            title="Start Group"
                                                            disabled={isStartDisabled(group.state, group.name)}
                                                        >
                                                            <PlayArrowIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleAction('stop', group.resourceGroup, group.name)}
                                                            title="Stop Group"
                                                            disabled={isStopOrRestartDisabled(group.state, group.name)}
                                                        >
                                                            <StopIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleAction('restart', group.resourceGroup, group.name)}
                                                            title="Restart Group"
                                                            disabled={isStopOrRestartDisabled(group.state, group.name)}
                                                        >
                                                            <RestartAltIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <Collapse in={expandedGroup === group.name} timeout="auto" unmountOnExit>
                                                    <CardContent>
                                                        <Typography variant="h6">Containers:</Typography>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Container Name</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell>Actions</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {group.containers.map((container) => (
                                                                    <TableRow key={container.name}>
                                                                        <TableCell>{container.name}</TableCell>
                                                                        <TableCell>{container.status}</TableCell>
                                                                        <TableCell>
                                                                            <IconButton onClick={() => handleViewLogs(group.resourceGroup, group.name, container.name)} title="View Logs">
                                                                                <DescriptionIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </CardContent>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        )}
                    </CardContent>
                </Card>
        </Grid>
    </Grid>
    );
};

export default AdminPortal;


// import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
// import { getAdminPortalData } from '../services/api';
// const AdminPortal = () => {
//     const pageRefresh = useRef(true);
//     const [users, setUsers] = useState([]);
//     const [continuationToken, setContinuationToken] = useState(null);

//     const fetchNextPage = useCallback(async () => {
//         const { users: userInfo, continuationToken: continuation } = await getAdminPortalData(5, continuationToken);

//         setUsers(prevUsers => [...prevUsers, ...userInfo]);
//         setContinuationToken(continuation);
//     },[continuationToken]);

//     useEffect(() => {
//         // Fetch the first page of data
//         if (pageRefresh.current) {
//             fetchNextPage();
//             pageRefresh.current = false;
//         }
//     }, [pageRefresh, fetchNextPage]);

    

//     return (
//         <div>
//             <Table>
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Name</TableCell>
//                         <TableCell>Gamertag</TableCell>
//                         <TableCell>Instance ID</TableCell>
//                         <TableCell>Progress</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {users.map((user, index) => (
//                         <TableRow key={index}>
//                             <TableCell>{user.username}</TableCell>
//                             <TableCell>{user.gamerTag}</TableCell>
//                             <TableCell>{user.instanceId}</TableCell>
//                             <TableCell>{user.progress}</TableCell>
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//             {continuationToken && (
//                 <Button onClick={fetchNextPage}>Load More</Button>
//             )}
//         </div>
//     );
// };

// export default AdminPortal;