import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#212121',
      light: '#505050',
    },
    secondary: {
      main: '#c86e31',
    },
    info: {
      main: '#328991',
    },
    background: {
      default: '#eaeaee',
    },
    text: {
      hint: '#1e3a45',
    },
    darkbutton: {
          main: '#fff',
          light: '#b3b7b4',
          dark: '#a3a7a4',
          contrastText: '#333',
        },
  },
  // palette2: {
  //   type: 'light',
  //   primary: {
  //     main: '#2b2b2b',
  //     light: '#585858',
  //     dark: '#141414',
  //   },
  //   secondary: {
  //     main: '#c86e31',
  //   },
  //   info: {
  //     main: '#328991',
  //   },
  // },
  // palette: {
  //   primary: {
  //     main: '#1976d2',
  //     light: '#42a5f5',
  //     dark: '#1565c0',
  //     contrastText: '#fff',
  //   },
  //   secondary: {
  //     main: '#dc004e',
  //     light: '#ff5c8d',
  //     dark: '#9e0039',
  //     contrastText: '#000',
  //   },
  //   darkbutton: {
  //     main: '#fff',
  //     light: '#b3b7b4',
  //     dark: '#a3a7a4',
  //     contrastText: '#333',
  //   },
  //   mode: 'light', // or 'dark' if you are using dark mode
  // },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);
// reportWebVitals();
