// Define the base API URL from environment variables
const API_URL = process.env.REACT_APP_CUSTOM_API_URL;

/**
 * Check and register a user.
 * @param {Object} user - The user object containing user information and token.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const checkAndRegisterUser = async (user) => {
  const response = await fetch(`${API_URL}/auth/check-register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error('Failed to check and register user');
  }

  return await response.json();
};

export const getProductList = async () => {
  const response = await fetch(`${API_URL}/store/products`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to load products. Please try again later.');
  }

  return await response.json();
}

/**
 * Use this to checkout a user for a specific product.
 * TODO: determine if we stick with the productID in the URL or store it in the body
 * 
 * @param {*} data 
 * @param {*} productId 
 * @returns 
 */
export const registerAndCheckoutUser = async (data, productId) => {
  const response = await fetch(`${API_URL}/auth/register-sso/${productId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({user: data}),
  });

  if (!response.ok) {
    throw new Error('Failed to register and checkout user');
  }

  return await response.json();
}

export const validateAdminToken = async (token) => {
  const response = await fetch(`${API_URL}/admin/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });
  if (!response.ok) {
    const resp = await response.json();
    throw new Error(`Failed to login: ${resp.error}`);
  }

  return await response.json();

}

export const tryAdminLogin = async (user) => {
  const response = await fetch(`${API_URL}/admin/admin-login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.accessToken}`
    },

  });
  
  if (!response.ok) {
    const resp = await response.json();
    throw new Error(`Failed to login: ${resp.error}`);
  }

  return await response.json();
}


export const getAdminPortalData = async (token, pageSize, continuationToken) => {
  const response = await fetch(`${API_URL}/admin/admin-portal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ pageSize: pageSize, continuationToken: continuationToken }),
  });

  if (!response.ok) {
    const resp = await response.json();
    throw new Error(`Failed to get Admin Data: ${resp.error}`);
  }

  return await response.json();
}


/**
 * Register a user with SSO.
 * @param {Object} data - The data object containing user information.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const registerUserSSO = async (data) => {
  const response = await fetch(`${API_URL}/auth/register-sso`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const resp = await response.json();
    throw new Error(`Failed to register with SSO: ${resp.error}`);
  }

  return await response.json();
};

/**
 * Store user data.
 * @param {Object} data - The data object containing user information.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const storeUserData = async (data) => {
  const response = await fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const resp = await response.json();
    throw new Error(`Failed to register: ${resp.error}`);
  }

  return await response.json();
};

/**
 * Log in a user.
 * @param {Object} data - The data object containing user credentials.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const loginUser = async (data) => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to login: ${errorData.error}`);
  }

  const result = await response.json();
  console.log(`Login Result: ${result.user}`); // Debugging log
  return result;
};

/**
 * Log in a user with SSO.
 * @param {Object} data - The data object containing user credentials.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const loginUserSSO = async (data) => {
  const response = await fetch(`${API_URL}/auth/login-sso`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorMsg = await response.json();
    throw new Error(`Failed to login with SSO: ${errorMsg.error}`);
  }

  const result = await response.json();
  console.log(`Login SSO Result: ${result.user}`); // Debugging log
  return result;
};

/**
 * Update user data.
 * @param {Object} data - The data object containing updated user information.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const updateUserData = async (data) => {
  const response = await fetch(`${API_URL}/profile`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to update user data');
  }

  return await response.json();
};

/**
 * Get user profile.
 * @returns {Promise<Object>} - The response JSON object containing user profile.
 * @throws {Error} - If the request fails.
 */
export const getUserProfile = async () => {
  const response = await fetch(`${API_URL}/profile`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const responseText = await response.json();
    throw new Error('Failed to fetch user profile: ' + (responseText.error ?? response.status));
  }

  return await response.json();
};

/**
 * Validate an access key.
 * @param {string} accessKey - The access key to validate.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const validateAccessKey = async (accessKey) => {
  const response = await fetch(`${API_URL}/auth/validate-access-key`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accessKey }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return await response.json();
};

/**
 * Validate the access session.
 * @returns {Promise<Object>} - The response JSON object.
 * @throws {Error} - If the request fails.
 */
export const validateAccessSession = async () => {
  const response = await fetch(`${API_URL}/auth/get-access-key`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return await response.json();
};

/**
 * Log out the user.
 * @returns {Promise<void>} - Resolves when the logout is complete.
 */
export const logoutUser = async () => {
  await fetch(`${API_URL}/auth/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  // Optionally clear any tokens or user data stored in cookies or local storage
  // Cookies.remove('token');
};

/************** Cosmos DB Management ****************/
export const resetInstanceId = async (token, userId) => {
  const response = await fetch(`${API_URL}/admin/reset-instance-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    throw new Error('Failed to reset instance ID');
  }

  return await response.json();
};

export const resetProgress = async (token, userId) => {
  const response = await fetch(`${API_URL}/admin/reset-progress`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    throw new Error('Failed to reset progress');
  }

  return await response.json();
};

/************* Container Services *******************/
export const getContainerData = async (token) => {
  const response = await fetch(`${API_URL}/admin/containers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to load container data. Please try again later.');
  }

  return await response.json();
}

export const startContainerGroup = async (token, resourceGroup, containerGroup) => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to start container group');
  }

  return await response.text();
}

export const stopContainerGroup = async (token, resourceGroup, containerGroup) => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/stop`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to stop container group');
  }

  return await response.text();
}

export const restartContainerGroup = async (token, resourceGroup, containerGroup) => {
  const response = await fetch(`${API_URL}/admin/containers/${resourceGroup}/${containerGroup}/restart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  if (!response.ok) {
    throw new Error('Failed to restart container group');
  }

  return await response.text();
}

export const getContainerLogs = async (token, resourceGroup, containerGroup, containerName) => {
  const response = await fetch(`${API_URL}/admin/containerLogs/${resourceGroup}/${containerGroup}/${containerName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` //
    },
  });

  if (!response.ok) {
    throw new Error('Failed to get container logs');
  }

  return await response.json();
};