import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from '../services/api';
import { clearToken } from '../utils/session';
import { msalInstance } from '../msalConfig';
// import logo_300 from '../assets/logo300_300_white.png';
import full_falcon from '../assets/logo_300_white_full.png';

const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
  "color": "inherit",
  ':hover': {
    backgroundColor: theme.palette[color].light,
    // backgroundColor: 'white',
  },
}));

const Header = ({ user }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutUser();
    clearToken();
    sessionStorage.clear();
    msalInstance.logoutRedirect(
      {
        postLogoutRedirectUri: window.location.origin,
        
      }
    );
    // navigate('/login');
    // window.location.reload(); // Optional: reload the page to update the state
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* <HomeOutlinedIcon sx={{height: '50px', mr: 3, fontSize: 40}}/> */}
        {/* <IconButton edge="start" color="inherit" aria-label="home" component={Link} to="/"  sx={{
    ':hover': {
      bgcolor: 'primary.light', // theme.palette.primary.main
      color: 'white',
      borderRadius: 0,
    },
  }}> */}
        <StyledButton color="primary" component={Link} to="/">
        <img src={full_falcon} alt="Pedegree Studios" style={{height: '50px', borderRadius: 0,}} />
        </StyledButton>
        {/* </IconButton> */}
        
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={async () => {navigate('/')}}>
          
        </Typography>
        {user ? (
          <>
            <StyledButton color="primary" component={Link} to="/profile">Profile</StyledButton>
            <StyledButton color="primary" onClick={handleLogout}>Logout</StyledButton>
          </>
        ) : (
          <>
          {/* <Button color="inherit" component={Link} to="/">Store</Button> */}
            <StyledButton color="primary" component={Link} to="/login">Login</StyledButton>
            
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
