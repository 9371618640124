import React, { useEffect, useRef, useCallback, useState, } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Button, Box, CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { Microsoft } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { registerAndCheckoutUser } from '../services/api';
import { getMSALToken, msalInstance } from '../msalConfig';


const Register = ({ onRegister }) => {
    // const {productId} = useParams();
    const pageRefresh = useRef(true);
    const [loading, setLoading] = useState(false);
    const { productId } = useLocation().state || {};

  
  
    
  const handleMSALLogin = useCallback(async () => {
    setLoading(true);
    try {
      const user = await getMSALToken();
      const { user: userToken, session: stripeSession } = await registerAndCheckoutUser(user, productId);
    //   const response = await registerUserSSO(user);
      await onRegister(userToken, stripeSession.url);
      
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error(error.message || 'Failed to login with Microsoft');
      // toast.error('Failed to login with Microsoft');
    }finally {
        setLoading(false);
    }
  },[onRegister, productId]);

  useEffect(() => {
    if (pageRefresh.current === true) {
      console.log('Page Refreshed');
      pageRefresh.current = false;
      const account = msalInstance.getAllAccounts()[0];
      // const account = msalInstance.getActiveAccount();
      console.log(account)
      if (account) {
        handleMSALLogin();
      }
    }
  },[pageRefresh, handleMSALLogin]);

  return (
  <Box sx={{ mt: 4, px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ToastContainer />
      <Typography variant="h6">Please Create an Account to Continue</Typography>
      <Button 
        variant="contained" 
        startIcon={!loading && <Microsoft />} 
        color="darkbutton" 
        sx={{ mt: 2, ml: 2 }}
        disabled={loading} 
        onClick={handleMSALLogin}>
           {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
          Register with Microsoft
      </Button>
        <Typography variant="body2" sx={{ mt: 2 }}>
            Please register using your Minecraft account to continue.
        </Typography>
    </Box>   
  ); 
};

export default Register;