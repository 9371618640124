import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Button, Typography, Box, Card, CardContent, CardMedia, CardActions, Chip, Grid, TextField, CircularProgress } from '@mui/material';
import { validateAccessKey, validateAccessSession, getProductList } from '../services/api';

const Storefront = () => {
  const navigate = useNavigate();
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAccess, setLoadingAccess] = useState(false); // Loading state

  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    const loadProducts = async () => {
      const products = await getProductList();
      
      //Sort by created date - oldest to newest.
      //TODO: improve this by picking the order somehow...
      products.data.sort((a, b) => new Date(a.created) - new Date(b.created));
      setProducts(products.data);
    };

    if(authenticated){
      loadProducts();
    };

  },[authenticated]);

  useEffect(() => {
    // const token = Cookies.get('token');
    // if (token) {
    validateAccessSession().then(token => {
        setAccessKey(token.accessKey.key);
        // console.log(`Valid Access Key: ${token.accessKey}`);
        if(token.accessKey.validated){
            setAuthenticated(true);
            // setDiscount(token.accessKey.discountAmount);
        }
        
    }).catch(error => {
        // console.error('Error fetching user profile:', error);
    }) //toast.error('Error fetching user profile:', error));

  }, []);

  const handleBuyNow = (product) => {
    // console.log('Buying product:', product.id);
    // console.log(`product metadata ID: ${product.metadata.productId}`);
    navigate(`/register/purchase`, { state: { productId: product.id, priceId: product.default_price?.id } });
  };

  const handleAccessKeySubmit = async (e) => {
    e.preventDefault();
    setLoadingAccess(true);
    try {
      const response = await validateAccessKey(accessKey);
      // console.log(response);
      if(response.success){
        setAuthenticated(true);
        // setDiscount(response.discountAmount);
        // setOpen(false);
      }
    } catch (err) {
      setError(err.message);
    }
    finally{
      setLoadingAccess(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      {!authenticated && (
        <Card sx={{ maxWidth: 450 }}>
          <CardContent>
            <Typography variant="h5" align='center'>🕹️ Welcome, Alpha Testers! 🎮</Typography>
            <Typography variant="body2" color="text.secondary">Please enter your access key to participate in the alpha testing program and create your account. Key not working? Contact 
            {' '}
              <Link href="#" rel="noopener">
                Support
              </Link>
            {' '} for assistance.
            </Typography>
            <TextField
            autoFocus
            margin="dense"
            label="Access Key"
            error={!!error}
            helperText={error}
            type="text"
            fullWidth
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            onKeyDown={(e) => {if(e.key === 'Enter') handleAccessKeySubmit(e)}}
          />
          </CardContent>
          <CardActions sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            { loadingAccess ? (
              <CircularProgress justifyContent="center" size={24} />
            ) : (
              <Button 
                align="right"
                variant="outlined" 
                size="small" 
                color="primary" 
                onClick={handleAccessKeySubmit}
                >
                  Submit
                </Button>
            )
            }
            
          </CardActions>
        </Card>
      )}
  
      {authenticated && (
        <Grid container spacing={2} sx={{alignItems: 'top', justifyContent: 'center'}}>
          {products.map((product) => (
        <Grid item key={product.id}>
          <Card sx={{ maxWidth: 450, height:"100%", display:"flex", flexDirection: "column" }}>
            <CardMedia
              component="img"
              alt="Product Image"
              height="300"
              image={product.images[0]}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {product.name}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{mb:2}}>
                {product.description}
              </Typography>
              
              {product.default_price && (
                <>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="h6" color="text.secondary" sx={{ textDecoration: 'line-through', display: 'inline' }}>
                      ${product.default_price.unit_amount / 100}
                    </Typography>
                    <Chip label={`Alpha-Testing Discount`} color="secondary" size="small" sx={{ ml: 1 }} />
                  </Box>
                  <Typography variant="body1" color="text.secondary" sx={{ display: 'inline', ml: 1 }}>
                    See price in cart
                  </Typography>
                </>
              )}
              
            </CardContent>
            <CardActions sx={{mt:"auto", mb:1}}>
            {product.default_price ? (
              
                <Button 
                size="large" 
                fullWidth={true} 
                variant="contained" 
                color="secondary" 
                onClick={() => handleBuyNow(product)}>
                  Buy Now
                </Button>
              
            ) : (
              
                <Button size="large" disabled={true} fullWidth={true} variant="outlined" color="secondary" onClick={() => handleBuyNow(product)}>
                  Coming Soon!
                </Button>
              
            )}
            </CardActions>
          </Card>
        </Grid>
      ))}
      </Grid>
      )}
    </Box>
  );
};

export default Storefront;