import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography,  Box, CardContent, Avatar, Card, CardHeader, Button, CircularProgress, Link } from '@mui/material';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { ToastContainer, toast } from 'react-toastify';
import { getUserProfile } from '../services/api';
import { deepPurple } from '@mui/material/colors';
import 'react-toastify/dist/ReactToastify.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import HttpIcon from '@mui/icons-material/Http';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { useNavigate } from 'react-router-dom';

const Profile = ({ user, onUpdateProfile }) => {
  const { password, playerId, ...displayData } = user;
  const [formData, setFormData] = useState(displayData);
  const [dlcData, setDlcData] = useState([]);
  const [loadingUserData, setLoadingUserData] = useState(false);

  // const [newProperty, setNewProperty] = useState({ key: '', value: '' });
  // const [isFormChanged, setIsFormChanged] = useState(false);
  const pageRefresh = useRef(true);
  const navigate = useNavigate();

  
  useEffect(() => {
    // Function to fetch user profile
    const fetchUserProfile = async () => {
      
      try {
        setLoadingUserData(true);
        const { user: profile, dlcList: dlc } = await getUserProfile();
        setFormData(profile);
        // console.log(`DLC Data: ${JSON.stringify(dlc)}`);
        if(dlc){
          setDlcData(dlc);
        }
        
        // setIsFormChanged(false);
      } catch (error) {
        toast.error('Failed to fetch user profile');
        console.error('Error fetching user profile:', error);
        navigate('/login');
      } finally {
        setLoadingUserData(false);
      }
    };
    // Call the fetch function only once - at the initial page load
    if (pageRefresh.current === true) {
      fetchUserProfile();
      pageRefresh.current = false;
    }
  }, [pageRefresh, navigate]);

  //Only run this effect when the user object changes and the page is not refreshed
  useEffect(() => {
    if (!pageRefresh.current) {
      setFormData(user);
      // setIsFormChanged(false);
    }
  }, [user, pageRefresh]);

  // const handleChange = (e, key) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [key || name]: value,
  //   }));
  //   setIsFormChanged(true);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await onUpdateProfile(formData); //doesn't return anything
  //     //triggers an update to the user object
  //     // setFormData(displayData);
  //     toast.success('Profile updated successfully!');
  //     // setIsFormChanged(false);
  //   } catch (error) {
  //     toast.error('Failed to update profile');
  //   }
  // };

  // const handleAddProperty = () => {
  //   if (newProperty.key && newProperty.value) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [newProperty.key]: newProperty.value,
  //     }));
  //     setNewProperty({ key: '', value: '' });
  //     setIsFormChanged(true);
  //   }
  // };

  // const handleRemoveProperty = (key) => {
  //   const { [key]: _, ...rest } = formData;
  //   setFormData(rest);
  //   setIsFormChanged(true);
  // };

  // const undeletableKeys = ['username', 'email', 'birthday'];
  // const lockedKeys = ['username', 'email', 'birthday', 'name', 'oid', 'token', 'xuid', 'gamertag', 'ageGroup', 'accessKey'];

  // const lastNameInitial = ;

  return (
    <Box sx={{ mt: 4, px: 3 }}>
      <ToastContainer />

      <Grid container spacing={2} sx={{ justifyContent: 'left' }}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <CardHeader sx={{ display: 'flex' }} title="Profile"></CardHeader>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={2} sm={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: deepPurple[500] }}>{user.name[0]}{user.name.split(' ')[1][0]}</Avatar>
                </Grid>
                <Grid item xs={10} sm={9} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    {formData.name}
                  </Typography>
                </Grid>
                <Grid container spacing={.5} sx={{ mt: 4 }}>
                  <Grid item xs={12} sm={12} sx={{ justifyContent: 'right', display: 'flex', alignItems: 'center' }}>

                    <Typography variant="body2" color="text.secondary">
                      {formData.email}
                    </Typography>
                    <EmailOutlinedIcon sx={{ color: 'action', ml: 2 }} />
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ justifyContent: 'right', display: 'flex', alignItems: 'center' }}>

                    <Typography variant="body2" color="text.secondary">
                      {formData.gamertag}
                    </Typography>
                    <VideogameAssetIcon sx={{ color: 'action', ml: 2 }} />
                  </Grid>
                </Grid>
              </Grid>


            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={4}></Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Typography variant="h5" gutterBottom>
            Subscriptions
          </Typography>
          <Grid container spacing={2}>
            {formData.active && (loadingUserData ? <CircularProgress size={36} /> : (
            <Grid item xs={12} lg={6}>
              <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                <CardHeader
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  title="Moore's Law DLC"
                  action={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'green',
                        width: 100,
                        height: 40,
                        borderRadius: 1,
                      }}
                    >
                      <Typography sx={{ color: 'white', fontWeight: 'bold' }}>Active</Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Grid container spacing={.5}>
                    <Grid item xs={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" gutterBottom>
                        Access your subscription by connecting to the Minecraft server below. Need some help? Find step-by-step instructions with screenshots on our <Link target="_blank" rel="noopener noreferrer" href="https://pedegreestudios.freshdesk.com/support/solutions/articles/154000183390" >Knowledge Base↗</Link>.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center' }}>

                      <HttpIcon sx={{ color: 'action', ml: 2, mr: 2, }} />
                      <Typography variant="body2">
                        {(dlcData && dlcData.length > 0) ? dlcData[0]['url'] : "NO URL FOUND"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{ justifyContent: 'left', display: 'flex', alignItems: 'center' }}>
                      <ElectricalServicesIcon sx={{ color: 'action', ml: 2, mr: 2, }} />
                      <Typography variant="body2" gutterBottom>
                      {(dlcData && dlcData.length > 0) ? dlcData[0]['port'] : "NO PORT FOUND"}
                      </Typography>
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Grid>))}
            {!formData.active && (
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" gutterBottom>
                You have no active subscriptions.
                </Typography>
                <Button size="medium" color="secondary" variant="contained" endIcon={<LocalMallOutlinedIcon />} onClick={() => navigate('/store')}>
                    Store
                </Button>

                </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} >
          {/* <Typography variant="h5" gutterBottom>
            Feedback
            </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                <CardHeader
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  title="Feedback"
                />
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    Please provide feedback on your experience with the Alpha Testing program.
                  </Typography>
                  <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
                  <style type="text/css" media="screen, projection">
                    @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); 
                  </style> 
                  <iframe 
                    title="Feedback Form" 
                    className="freshwidget-embedded-form" 
                    id="freshwidget-embedded-form" 
                    src={`https://pedegreestudios.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback&helpdesk_ticket[requester]=${formData.email}&helpdesk_ticket[email]=${formData.email}&helpdesk_ticket[name]=${formData.name}&helpdesk_ticket[subject]=Alpha Testing Feedback`}
                    scrolling="no" height="500px" width="100%" frameBorder="0" >
                  </iframe>
                </CardContent>
              </Card>
            </Grid>
            </Grid>
        </Grid>
      </Grid>

    </Box>
  );
};

export default Profile;
