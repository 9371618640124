import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import AccountCreationForm from './components/AccountCreationForm';
import Profile from './components/Profile';
import Login from './components/Login';
import Header from './components/Header';
import AdminPortal from './components/AdminPortal';
// import AccessKeyPage from './components/AccessKey';
import Storefront from './components/Storefront';
// import { toast} from 'react-toastify';
import { updateUserData } from './services/api';
// import Cookies from 'js-cookie';
import { Typography } from '@mui/material';
import Register from './components/Register';
// import RegistrationForm from './components/RegistrationForm';
import { msalInstance } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';

const App = () => {
  const [userData, setUserData] = useState(() => {
    // Retrieve user data from sessionStorage if it exists
    const savedUser = sessionStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [authenticated, setAuthenticated] = useState(() => {
    // Check if user data exists in sessionStorage to set the initial authenticated state
    return sessionStorage.getItem('user') !== null;
  });
  // const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      sessionStorage.setItem('user', JSON.stringify(userData));
    } else {
      sessionStorage.removeItem('user');
    }
  }, [userData]);


  // useEffect(() => {

  //     getUserProfile().then(user => {
  //       setUserData(user.user);
  //       navigate('/profile');
  //     }).catch(error => {}) //toast.error('Error fetching user profile:', error));
  //   // }
  // }, [authenticated]);

  const handleCheckout = (user, url) => {
    setUserData(user);
    setAuthenticated(true);
    //Redirect to the checkout page
    window.location.href = url;
  }



  const handleLogin = (user, tenant) => {
    setUserData(user);
    setAuthenticated(true);
    if (tenant){
      // setAdmin(true);
      navigate('/admin');
    }
    else{
      navigate('/profile');
    }
    
  };

  const handleUpdateProfile = async (data) => {
    try {
      const {user: updatedData} = await updateUserData(data);
      setUserData(updatedData);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  return (
    <>
    <MsalProvider instance={msalInstance}>
     <Header user={userData} />
      <Routes>
      <Route path="/" element={authenticated ? 
        <Navigate to="/profile" replace /> : 
        <Storefront />
        } />
        <Route path="/store"  element={<Storefront />}/>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        {/* <Route path="/access-key" element={<AccessKeyPage />} /> */}
        <Route path="/register/:productId" element={<Register onRegister={handleCheckout} />} />
        {/* <Route path="/register" element={<RegistrationForm onSubmit={handleLogin} />} /> */}
        <Route
          path="/profile"
          element={
            userData ? (
              <Profile user={userData} onUpdateProfile={handleUpdateProfile} />
            ) : (
              <Typography>Loading...</Typography>
            )
          }
        />
        <Route path="/admin" element={<AdminPortal />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />  
        </Routes>
      </MsalProvider>
      
      
    </>
  );
};

export default App;
