import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loginUser, loginUserSSO, tryAdminLogin } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { Microsoft } from '@mui/icons-material';
// import {useMsal} from '@azure/msal-react';
import { getMSALToken, msalInstance, getPedegreeMsalToken } from '../msalConfig';
// import { setToken } from '../utils/session';
// import ReCAPTCHA from 'react-google-recaptcha';

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({ username: '', password: '', recaptchaToken: '' });
  const [captchaVerified] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const { instance } = useMsal();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePedegreeLogin = async() => {
    try{
      setLoading(true);
      const user = await getPedegreeMsalToken();
      console.log('User:', user);

      tryAdminLogin(user)
        .then(() => {
          onLogin(user, user.tenantId);
        })
        .catch((error) => {
          toast.error(error.message || 'Failed to login with Pedegree');
          msalInstance.clearCache();
      });
    }catch(error){
      console.error('Error logging in:', error);
      toast.error('Failed to login with Pedegree');
    }finally {
      setLoading(false);
    }
  };

  const handleMSALLogin = async () => {
    try {
      setLoading(true);
      const user = await getMSALToken();
      loginUserSSO(user)
        .then((response) => {
          onLogin(response.user);
        })
        .catch((error) => {
          toast.error(error.message || 'Failed to login with Microsoft');
          msalInstance.clearCache();
      });
      
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Failed to login with Microsoft');
    }finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert('Please verify the captcha.');
      return;
    }

    try {
      const response = await loginUser(formData);
      onLogin(response.user);
      navigate('/profile');
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Invalid username or password');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}
    > 
      <ToastContainer />
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        name="username"
        value={formData.username}
        disabled={loading}
        onChange={handleChange}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        disabled={loading}
        onChange={handleChange}
        required
        sx={{ mb: 2 }}
      />
      {/* <ReCAPTCHA sitekey="your-recaptcha-site-key" onChange={handleCaptcha} /> */}
      <Button type="submit" disabled={loading} variant="contained" color="primary" sx={{ mt: 2 }}>
        Login
      </Button>
      <Button 
        variant="contained" 
        startIcon={!loading && <Microsoft />} 
        color="primary" 
        sx={{ mt: 2, ml: 2 }} 
        disabled={loading}
        onClick={handleMSALLogin}>
          {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
          Sign in with Microsoft
      </Button>
      <Button 
        variant="contained" 
        startIcon={!loading && <Microsoft />} 
        color="primary" 
        sx={{ mt: 2, ml: 2 }} 
        disabled={loading}
        onClick={handlePedegreeLogin}>
          {loading && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
          Admin Login (SSO)
      </Button>
      {/* <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, ml: 2 }}
          onClick={() => navigate('/access-key')}
        >
          Register (Access Code)
        </Button> */}
    </Box>
  );
};

export default Login;
